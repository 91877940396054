.ct-sm-line-chart,
.ct-area-ln-chart,
#ct-polar-chart,
.ct-svg-chartct-bar-chart, .total-revenue, .chartist-chart {
    position: relative;
}

.ct-sm-line-chart .ct-series-a .ct-line,
.ct-bar-chart .ct-series-a .ct-bar,
.ct-sm-line-chart .ct-series-a .ct-point,
.ct-donute-chart .ct-series-a .ct-slice-donut,
.ct-gauge-chart .ct-series-a .ct-slice-donut,
.ct-area-ln-chart .ct-series-a .ct-line,
.ct-area-ln-chart .ct-series-a .ct-point,
.ct-animation-chart .ct-series-a .ct-line,
.ct-animation-chart .ct-series-a .ct-point,
.ct-svg-chart .ct-series-a .ct-line,
.user-analytics .ct-series-a .ct-line,
.user-analytics .ct-series-a .ct-point{
    stroke: #009efb;
}

.ct-sm-line-chart .ct-series-b .ct-line,
.ct-bar-chart .ct-series-b .ct-bar,
.ct-sm-line-chart .ct-series-b .ct-point,
.ct-donute-chart .ct-series-b .ct-slice-donut,
.ct-gauge-chart .ct-series-b .ct-slice-donut,
.ct-animation-chart .ct-series-b .ct-line,
.ct-animation-chart .ct-series-b .ct-point,
.ct-svg-chart .ct-series-b .ct-line {
    stroke: #f62d51;
}

.ct-sm-line-chart .ct-series-c .ct-line,
.ct-sm-line-chart .ct-series-c .ct-point,
.ct-donute-chart .ct-series-c .ct-slice-donut,
.ct-gauge-chart .ct-series-c .ct-slice-donut,
.ct-animation-chart .ct-series-c .ct-line,
.ct-animation-chart .ct-series-c .ct-point,
.ct-svg-chart .ct-series-c .ct-line {
    stroke: #26c6da;
}

.ct-gauge-chart .ct-series-d .ct-slice-donut,
.ct-donute-chart .ct-series-d .ct-slice-donut {
    stroke: #ffbc34;
}

.ct-donute-chart .ct-series-e .ct-slice-donut {
    stroke: #4c5667;
}

.ct-donute-chart .ct-series-f .ct-slice-donut {
    stroke: #02bec9;
}

.ct-area-ln-chart .ct-series-a .ct-area,
.ct-svg-chart .ct-series-a .ct-area {
    fill: #009efb;
}
.user-analytics .ct-series-a .ct-area{
    fill:none;
}

/*Android vs ios chart*/
.andvios .ct-series-a .ct-line,
.andvios .ct-series-b .ct-line
{
    stroke: transparent;
   
}
.andvios .ct-series-a .ct-point, .andvios .ct-series-b .ct-point{
    stroke-width:3px;
    stroke:#26c6da;
}
.andvios .ct-series-a .ct-area{
    fill:#1eacbe;
    fill-opacity:0.3;
}
.andvios .ct-series-b .ct-area{
    fill:#26c6da;
    fill-opacity:0.7;
}
/*Bandwidth data usege*/
.usage .ct-series-a .ct-line{
    stroke:#fff;
}
.usage .ct-series-a .ct-point{
    stroke-width:0px;
}
.usage .ct-series-a .ct-area{
    fill-opacity:0;
}

/*Download stats */
.download-state .ct-series-b .ct-bar, .download-state .ct-series-a .ct-bar, .download-state .ct-series-c .ct-bar {
     stroke-width:3px;
}
.download-state .ct-series-c .ct-bar {
    stroke:#98aab4;
}
.download-state .ct-series-b .ct-bar{
    stroke:#26c6da;
}
.download-state .ct-series-a .ct-bar{
    stroke:#7460ee;
}