
div._activate-overlay-application-loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  background-color: #0000009a;
  z-index: 100;

  ._loading-animation {
    position: relative;
    height: 100px;
    width: 100px;
    margin: 0 auto 0;
    top: 45%;
  }
}

div._deactivate-overlay-application-loading {
  ._loading-animation {
    display: none;
  }
}

body._not_scroll {
  overflow: hidden;
}
