@import '~bootstrap/dist/css/bootstrap.min.css';

.list-group {
  flex-direction: row;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.qr-code {
  display: block;
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
}

.show-url-p {
  margin-top: 8px;
  display: inline-block;
  width: max-content;
  white-space: nowrap;
}

.button-copy {
  height: 36px;
  width: max-content;
  white-space: nowrap;
}