@import '~bootstrap/dist/css/bootstrap.min.css';

@import '~magnific-popup/dist/magnific-popup.css';
@import '~dropify/dist/css/dropify.min.css';

@import '../../css/style.css';
@import '../../css/colors/red.css';
@import '../../css/style-zooo.css';

.mfp-img {
  max-height: 914px !important;
}
