/* Register & Login */

.page-wrapper-register {
  padding-bottom: 60px;
}

.page-wrapper-register .page-titles h3 {
  padding: 0;
}

.register-login-img {
  width: 95%;
  position: relative;
  top: -50px;
}

.register-login-title {
  position: relative;
  font-weight: 700;
  top: -50px;
}

/* Register box - registration wizard box */

.register-box {
  width: 800px;
  margin: 0 auto;
}

.register-box .footer {
  width: 100%;
  left: 0px;
  right: 0px;
}

.register-box .social {
  display: block;
  margin-bottom: 30px;
}

.login-sidebar .register-box {
  position: relative;
}

.login-sidebar .register-box {
  right: 0px;
  position: absolute;
  height: 100%;
}

.divider-dots {
  width: 20%;
  border-top: 9px dotted #eee;
}

.register-box .card-body {
  padding: 2rem 2rem 2rem 1.25rem;
}

.in-form-tooltip {
  position: relative;
  top: 30px;
}

/* Application confirmation page */

.success-feature-img {
  width: 400px;
  margin-top: 10px;
}

/* ZOOO gradation button */

/* Adjust bootstrap css*/
.btn-zooo {
  border-radius: 10px;
}

.btn-zooo-submit {
  width: 230px;
  height: 40px;
}

.text-ZOOOpink {
  color: #fc4b6c;
}

.profile-save-btn-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 100;
}

.coupon-action-btn tr td:last-child {
  width: 100%;
}

table {
  color: #455a64;
}

/* Adjust theme css*/
h3.text-themecolor {
  font-weight: 100;
  font-size: 2.5rem;
  padding-bottom: 20px;
  padding-top: 15px;
  line-height: 1.4;
}

.page-wrapper {
  background-color: #f6f6f6;
}

.business-tagline {
  position: relative;
  top: -40px;
  color: #455a64;
}

.product-title {
  font-weight: 600;
  color: white;
  padding: 0 0 0 10px;
}

.card-no-border .card {
  border-radius: 15px;
}

ul.store-name-list {
  list-style: none;
  padding-inline-start: 0;
  text-align: left;
}

ul.store-name-list li {
  padding: 10px 25px;
}

.store-name-nav-bar {
  font-weight: 400;
  font-size: 1.1rem;
}

.price-lable {
  font-size: 1rem;
  width: 165px;
  top: -15px;
}

.featured-plan .pricing-body {
  background: #f6f6f6;
}

.pricing-body {
  color: #455a64;
}

/* Business Profile page */
.serviceAmenity label {
  font-size: 0.9rem;
  width: calc(100% / 3.5);
}

.edit-slide-gallery {
  width: 200px;
}

.el-element-overlay .el-card-item {
  padding-bottom: 10px;
}

.list-group {
  flex-direction: row;
}

/* Create A Coupon - Coupon Preview*/

.card-no-border .card.coupon-preview {
  border-radius: 12px;
  border: 5px dashed #F2D518;
  width: 80%;
  max-width: 270px;
  min-height: 200px;
  margin: auto;
}

.coupon-preview-button {
  width: 100%;
  text-align: center;
}

.coupon-preview-date-time {
  color: #ff3399;
}

.card-no-border .card.coupon-preview button {
  background-color: #ff3399;
  color: #FFFFFF;
  border-radius: 50px;
  width: 70%;
  margin: auto;
}

.coupon-preview-headline {
  font-weight: 700;
  font-size: 1.4rem;
  color: #ff3399;
}

.coupon-preview-subhead,
.disclaimer-preview,
.voucher-preview p {
  font-weight: 400;
  color: #686868;
}

.coupon-preview-swipe-img {
  width: 90px;
  position: relative;
  top: -13px;
  right: -10px;
}

.voucher-preview {
  height: 400px;
}

.voucher-preview h3 {
  color: #ff3399;
  padding-top: 100px;
  padding-bottom: 15px;
}

.voucher-preview button {
  margin-top: 30px;
  width: 250px;
  box-shadow: 5px 5px 8px #cccccc;
}

.theme-feature-img {
  width: 400px;
}

.review-ul li {
  text-indent: 30px;
  color: #2c2c2c;
  font-weight: 400;
  padding: 5px 0;
}

.review-ul i {
  color: #40d0ad;
}

/* User Profile */
.user-gift-log {
  width: 100px;
  position: relative;
  display: inline-block;
  margin: 0 10px 15px 0;
}

.mailbox .message-center a .user-gift-log img {
  width: 100%;
}

.mailbox .message-center a .user-gift-log .profile-status {
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  left: 30px;
  position: absolute;
  top: 1px;
  width: 10px;
}

.check-in-history tbody tr td:nth-child(2) {
  text-align: center;
}

/* Coupon result page */
.coupon-result-headline {
  margin-top: 145px;
}

/*404 Error Page */

.error-rory {
  width: 150px;
}

/* Media queries */

@media (max-width: 767px) {
  .register-box {
    width: 90%;
  }

  .register-box .card-body {
    padding: 2rem;
  }

  .page-wrapper-register {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .card-no-border .card.coupon-preview {
    width: 100%;
  }
}

@media (min-width: 767px) {
  .page-wrapper-register .page-titles h6 {
    width: 55%;
  }

  .create-coupon-li {
    height: 0;
  }

  .coupon-preview-swipe-img {
    right: 0;
  }

  .create-a-coupon-button {
    width: 250px;
    position: relative;
    top: -55px;
    border-radius: 8px;
  }

  .create-coupon-li a {
    height: 0;
  }

  .coupon-action-btn tr td:last-child {
    width: 28%;
  }
}

@media (min-width: 960px) {
  /* Team profile*/
  .card-team-profile {
    margin: 0 auto;
    width: 40%;
  }
}
