
@import '~bootstrap/dist/css/bootstrap.min.css';

@import '~chartist/dist/chartist.min.css';
@import '../../css/chartist-init.css';
@import '../../css/chartist-plugin-tooltip.css';
@import '~c3/c3.min.css';

@import '../../css/style.css';
@import '../../css/colors/red.css';
@import '../../css/style-zooo.css';


.dashboard {
  .ct-chart {
    position: relative;
  }
  
  .ct-tooltip {
    position: absolute;
    display: inline-block;
    min-width: 5em;
    padding: 8px 10px;
    background: #383838;
    color: #fff;
    text-align: center;
    pointer-events: none;
    z-index: 100;
    transition: opacity .2s linear;
  }
  
  .ct-tooltip:before {
    position: absolute;
    bottom: -14px;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: rgba(251, 249, 228, 0);
    border-top-color: #383838;
    border-width: 7px;
    margin-left: -8px;
  }
  
  .ct-tooltip.hide {
    display: block;
    opacity: 0;
    visibility: hidden;
  }

  .download-state .ct-series-a .ct-bar {
    stroke: #1e88e5;
  }
  .download-state .ct-series-b .ct-bar {
    stroke: #eceff1;
  }
  .download-state .ct-series-c .ct-bar {
    stroke: #26c6da;
  }
}